import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  print,
  plotFunction,
  setWidth,
  drawLine,
  placePlay,
  placePause,
  writeMediumText,
  drawDash,
  drawIntersection,
  placeMiddleText,
  placeErase,
  drawSlider
} from '../Utils.js'
const Boxes = {
    box1: function () {
      var brd1=createSpace(-6, 10, -8, 8);
      brd1.options.axis.strokeWidth=0;
      brd1.options.layer['image']=17;
      brd1.options.layer['text']=19;
      brd1.options.layer['line']=16;
      brd1.options.layer['point']=17;
      brd1.options.layer['glider']=17;
      brd1.options.layer['angle']=2;
      brd1.options.board.minimizeReflow = 'none';
      brd1.options.point.showInfobox =false;
      brd1.options.line.highlight=false;
      brd1.options.image.highlight=false;
      brd1.options.text.highlight=false;
      brd1.options.circle.highlight=false;
    var ls2 = 1.0;
    var shft2 = 6.0;
    var a = 3.0;
    //
    makeResponsive(brd1);
    placeLogo(brd1);
    placeTitle(brd1, 'Thales Theorem', '');
    placeShuffle(brd1, 'left');
    placeErase(brd1);
    placeMiddleText(brd1, 2, 5.5, 'Points A, B can be moved on the circle.');
    placeMiddleText(brd1, 2, 4.5, 'Point C always remains diagonally opposite to point B.');
    placeMiddleText(brd1, 2, -4.5, 'The angle inscribed by diagonal BC at point A is always 90 degrees.');
    //Instruction
    //brd1.create('text',[-3.7, 5., 'Points A, B or C can be moved on the circle. Point D is diagonally opposite to point B. Drag point C to point D to construct a diameter of the circle.'],{cssStyle:'fontFamily:Oswald', fontSize:20, fixed:true});

    var orig =drawPoint(brd1, 0, 0);
    var rad = drawPoint(brd1, a, 0);
    rad.setAttribute({visible:false});
    var circ = drawCircle(brd1, orig, rad);
    circ.setAttribute({strokeColor:'black', fillOpacity:0.5});
    //Piston
    var pA2= drawSlider(brd1, circ, -a, 0.0);
    labelIt(brd1, pA2, 'A');
    var pB2=drawSlider(brd1, circ, 2.00, 2.23);
    labelIt(brd1, pB2, 'B');
    //
    var OB2=brd1.create('line',[orig, pB2],{strokeColor:'blue', strokeWidth:0, fixed:true});
    //Diagonal Point
    var pC2=brd1.create('intersection', [circ, OB2, 1], {name:'', size:3});
    labelIt(brd1, pC2, 'C');
    //var pC2 = drawIntersection(brd1, circ, OB2, 1);
    //Third Point
    //var pC2=brd1.create('glider',[2.00, -2.23, circ],{name:'C',size:3, face:'square',fillColor:'white',strokeColor:'black',shadow:true, attractors:[dp],attractorDistance:0.2, snatchDistance:2});
    //
    //vert
    var AB2=brd1.create('segment',[pA2, pB2],{strokeColor:'red', strokeWidth:3, fixed:true, visible:true});
    var AC2=brd1.create('segment',[pA2, pC2],{strokeColor:'red', strokeWidth:3, fixed:true, visible:true});
    //
    var OC2=brd1.create('segment',[orig, pC2],{strokeColor:'blue', strokeWidth:3, fixed:true, visible:true});
    var OB22=brd1.create('segment',[orig, pB2],{strokeColor:'blue', strokeWidth:3, fixed:true, visible:true});
    var CAB2 =brd1.create('nonreflexangle', [pC2, pA2, pB2], { radius:1, strokeWidth:2, name:'a', size:20, visible:true});
    var OAB2 =brd1.create('angle', [pC2, orig, pB2], {radius:1, strokeWidth:2, name:'b',size:20, visible:true});
    brd1.create('text', [6, 0.5, function() { return 'a (degree) ='+ JXG.toFixed(CAB2.Value()*180/Math.PI, 1); }], {cssStyle:'fontFamily:Oswald', fontSize:20, fixed:true});
    brd1.create('text', [6, -0.5, function() { return 'b (degree) ='+ JXG.toFixed(OAB2.Value()*180/Math.PI, 1); }], {cssStyle:'fontFamily:Oswald', fontSize:20, fixed:true});
    },
}
export default Boxes;
