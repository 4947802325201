<template>
  <div>
    <h3 ref="intro">
      Thales Theorem
    </h3>
    <p>
      <br>
      Thales theorem is a special case of the inscribed angle theorem. It states that the angle subtended by a diameter of a circle on any point on the circumference of the circle is
      always a right angle (i.e. \(90^o\)).
    </p>
    <h3 ref="pg">
      MagicGraph | Thales Theorem
    </h3>
    <p>
      <br>
      This MagicGraph provides a visually interactive illustration of the Thales theorem.<br><br>
      Points \(A\), \(B\) and \(C\) are three points on the circumference of the circle with center at \(O\). Points \(A\) and \(B\) can be moved on the circumference of the circle while point \(C\) always remains diagonally opposite to point \(B\) (i.e. \(BC\) is a diameter of the circle). <br><br>
      The angle 'a' is the inscribed angle by arc \(BC\) at point \(A\) while the angle 'b' is the central angle subtended by arc \(BC\) at the center \(O\) of the circle. <br><br>
      No matter where you place points \(A\) and \(B\) on the circumference of the circle, you will notice that the angle inscribed by arc \(BC\) at point \(A\) is always \(90^o\).
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ThalesTheorem',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Thales Theorem';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Thales Theorem', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Thales\'s Theorem',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
